import firebase from 'firebase/app';
import 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyAcNL8MWqjDBoBRVzx3-YQZnXyz1BVbXmY",
    authDomain: "hanutravels-300e5.firebaseapp.com",
    databaseURL: "https://hanutravels-300e5.firebaseio.com",
    projectId: "hanutravels-300e5",
    storageBucket: "hanutravels-300e5.appspot.com",
    messagingSenderId: "738149996118",
    appId: "1:738149996118:web:20967422055fa55fa8cdf7",
    measurementId: "G-VKC1PXB7PP"
  };

  firebase.initializeApp(firebaseConfig);

  const storage = firebase.storage();

  export { storage, firebase as default};