import React from "react";

import { storage } from "../../firebase/index";

import Card from "../components/Card";
import "./HomePage.css";
import {
  MdPhone,
  MdEmail,
  MdDirectionsCar,
  MdAttachMoney,
  MdAccountCircle,
  MdFlag,
} from "react-icons/md";
import {
  FaCar,
  FaGlobe,
  FaRegCompass,
  FaPlaneDeparture,
  FaRegMap,
  FaBriefcase,
  FaRegClock,
} from "react-icons/fa";
import { IconContext } from "react-icons";
import bannerImg from "../../assets/banner.jpg";
const tmpImg = require("../../assets/fleet/car (9).jpg");

const HomePage = () => {
  const fleet = [
    {
      title: "Swift",
      url:
        "https://firebasestorage.googleapis.com/v0/b/hanutravels-300e5.appspot.com/o/fleet%2Fcar%20(1).jpg?alt=media&token=7f614fca-803e-445c-9997-159dab3b0b7e",
    },
    {
      title: "Aspire",
      url:
        "https://firebasestorage.googleapis.com/v0/b/hanutravels-300e5.appspot.com/o/fleet%2Fcar%20(2).jpg?alt=media&token=7f614fca-803e-445c-9997-159dab3b0b7e",
    },
    {
      title: "EcoSport",
      url:
        "https://firebasestorage.googleapis.com/v0/b/hanutravels-300e5.appspot.com/o/fleet%2Fcar%20(3).jpg?alt=media&token=7f614fca-803e-445c-9997-159dab3b0b7e",
    },
    {
      title: "Zest",
      url:
        "https://firebasestorage.googleapis.com/v0/b/hanutravels-300e5.appspot.com/o/fleet%2Fcar%20(4).jpg?alt=media&token=7f614fca-803e-445c-9997-159dab3b0b7e",
    },
    {
      title: "Aspire",
      url:
        "https://firebasestorage.googleapis.com/v0/b/hanutravels-300e5.appspot.com/o/fleet%2Fcar%20(5).jpg?alt=media&token=7f614fca-803e-445c-9997-159dab3b0b7e",
    },
    {
      title: "Etios",
      url:
        "https://firebasestorage.googleapis.com/v0/b/hanutravels-300e5.appspot.com/o/fleet%2Fcar%20(6).jpg?alt=media&token=7f614fca-803e-445c-9997-159dab3b0b7e",
    },
    {
      title: "Innova",
      url:
        "https://firebasestorage.googleapis.com/v0/b/hanutravels-300e5.appspot.com/o/fleet%2Fcar%20(7).jpg?alt=media&token=7f614fca-803e-445c-9997-159dab3b0b7e",
    },
    {
      title: "Mobilio",
      url:
        "https://firebasestorage.googleapis.com/v0/b/hanutravels-300e5.appspot.com/o/fleet%2Fcar%20(8).jpg?alt=media&token=7f614fca-803e-445c-9997-159dab3b0b7e",
    },
    {
      title: "Swift Dzire",
      url:
        "https://firebasestorage.googleapis.com/v0/b/hanutravels-300e5.appspot.com/o/fleet%2Fcar%20(9).jpg?alt=media&token=7f614fca-803e-445c-9997-159dab3b0b7e",
    },
  ];
  return (
    <div className="body">
      <div className="mail-call-container">
        <div className="call-option">
          <MdPhone />
          <a href="tel:#">+91-9713429926</a>
        </div>
        <div className="mail-option">
          <MdEmail />
          <a href="mailto:#">shrihanutravels@gmail.com</a>
        </div>
      </div>

      <section id="top">
        <img className="banner" src={bannerImg} />
      </section>

      <section className="section-counters">
        <div class="container">
          <div class="row">
            <div class="col-6 col-md-3 wow fadeIn">
              <article class="counter-modern">
                <IconContext.Provider value={{ color: "orange", size: "2rem" }}>
                  <MdDirectionsCar />
                </IconContext.Provider>
                <div class="counter-modern-main">
                  <div class="counter">25</div>
                </div>
                <h4 class="font-weight-regular counter-modern-title">
                  Fleet Vehicles
                </h4>
              </article>
            </div>
            <div class="col-6 col-md-3 wow fadeIn" data-wow-delay=".2s">
              <article class="counter-modern">
                <IconContext.Provider value={{ color: "orange", size: "2rem" }}>
                  <MdAccountCircle />
                </IconContext.Provider>
                <div class="counter-modern-main">
                  <div class="counter">12</div>
                </div>
                <h4 class="font-weight-regular counter-modern-title">
                  Drivers
                </h4>
              </article>
            </div>
            <div class="col-6 col-md-3 wow fadeIn" data-wow-delay=".2s">
              <article class="counter-modern">
                <IconContext.Provider value={{ color: "orange", size: "2rem" }}>
                  <MdAttachMoney />
                </IconContext.Provider>
                <div class="counter-modern-main">
                  <div class="counter">₹ 9 /km</div>
                </div>
                <h4 class="font-weight-regular counter-modern-title">
                  Competitive Price
                </h4>
              </article>
            </div>
            <div class="col-6 col-md-3 wow fadeIn" data-wow-delay=".2s">
              <article class="counter-modern">
                <IconContext.Provider value={{ color: "orange", size: "2rem" }}>
                  <MdFlag />
                </IconContext.Provider>
                <div class="counter-modern-main">
                  <div class="counter">10K</div>
                </div>
                <h4 class="font-weight-regular counter-modern-title">
                  Annual Customers
                </h4>
              </article>
            </div>
          </div>
        </div>
      </section>

      <section className="about">
        <div className="specialities">
          <h2 className="section-heading">Our Specialities</h2>
          <ul>
            <li>Complete client satisfaction</li>
            <li>Ethical business policies</li>
            <li>Affordable pricing</li>
            <li>Reliable services</li>
            <li>Wide connectivity</li>
          </ul>
        </div>
        <div className="specialities-aside">
          Best Taxi Service in Madhya Pradesh, almost all the car rental service
          provider in the transport sector who claim for them as the best
          service. Undoubtedly, there is no competition between our service and
          their services. Everyone who traveled with us, in the past, know the
          fact, that we are the best service provider in the region. There are
          many ways to prove it that we are something different from others.
        </div>
      </section>

      <section id="services">
        <div className="row">
          <h2>Taxi Services</h2>
          <p className="section-description">
            With a wide range of cabs, riding on a daily basis, we assure to
            provide you with the best cab suiting your requirement. Our team of
            professional drivers care to give you a comfortable and smooth ride.
          </p>
          <div className="row">
            <div className="services-item">
              <article className="box-classic-light">
                <IconContext.Provider value={{ color: "orange", size: "4rem" }}>
                  <FaRegCompass />
                </IconContext.Provider>
                <h2 className="box-classic-title-light">As-Directed Service</h2>
                <p>
                  Choose your route and destination, we will take you there.
                </p>
              </article>
            </div>
            <div className="services-item">
              <article className="box-classic-light">
                <IconContext.Provider value={{ color: "orange", size: "4rem" }}>
                  <FaPlaneDeparture />
                </IconContext.Provider>
                <h2 className="box-classic-title-light">Airport Transfer</h2>
                <p>
                  For any need of pickup or drop at airport, call us and book a
                  cab.
                </p>
              </article>
            </div>
            <div className="services-item">
              <article className="box-classic-light">
                <IconContext.Provider value={{ color: "orange", size: "4rem" }}>
                  <FaGlobe />
                </IconContext.Provider>
                <h2 className="box-classic-title-light">City to City</h2>
                <p>
                  Want to go outside town, book our service for intercity
                  travels.
                </p>
              </article>
            </div>
            <div className="services-item">
              <article className="box-classic-light">
                <IconContext.Provider value={{ color: "orange", size: "4rem" }}>
                  <FaCar />
                </IconContext.Provider>
                <h2 className="box-classic-title-light">Guided Tours</h2>
                <p>
                  Book our cab to travel around the town with multiple stops.
                </p>
              </article>
            </div>
          </div>
        </div>
      </section>

      <section id="fleet">
        <div className="row">
          <h2>Our Fleet</h2>
          <span className="section-description">
            Choose from our wide range of cars for your trip.
          </span>
          <div className="row">
            <div className="horizontal-scroll">
              {fleet.map((item) => {
                // const carImg = require(item.url);
                return (
                  <Card className="fleet-item">
                    <img src={item.url} />
                    <h2>{item.title}</h2>
                  </Card>
                );
              })}
            </div>
          </div>
        </div>
      </section>

      <section id="advantages">
        <div className="row">
          <h2>Our Advantages</h2>
          <div className="row">
            <div className="services-item">
              <article className="box-classic-light">
                <IconContext.Provider value={{ color: "orange", size: "4rem" }}>
                  <FaRegMap />
                </IconContext.Provider>
                <h2 className="box-classic-title-light">Local Routes</h2>
                <p>
                  Taxi availability on all the routes in the city. Get a cab
                  wherever you need.
                </p>
              </article>
            </div>
            <div className="services-item">
              <article className="box-classic-light">
                <IconContext.Provider value={{ color: "orange", size: "4rem" }}>
                  <FaGlobe />
                </IconContext.Provider>
                <h2 className="box-classic-title-light">Outstation Travels</h2>
                <p>
                  Take benefit of our outstation services while traveling
                  outside the city.
                </p>
              </article>
            </div>
            <div className="services-item">
              <article className="box-classic-light">
                <IconContext.Provider value={{ color: "orange", size: "4rem" }}>
                  <FaBriefcase />
                </IconContext.Provider>
                <h2 className="box-classic-title-light">Business Travels</h2>
                <p>
                  Best taxi services for travelling businessmen in and around
                  the city.
                </p>
              </article>
            </div>
            <div className="services-item">
              <article className="box-classic-light">
                <IconContext.Provider value={{ color: "orange", size: "4rem" }}>
                  <FaRegClock />
                </IconContext.Provider>
                <h2 className="box-classic-title-light">24x7 Availability</h2>
                <p>
                  We offer cabs every day round the clock. Call us whenever you
                  need.
                </p>
              </article>
            </div>
          </div>
        </div>
      </section>

      <section className="terms">
        <h2 className="section-heading">Terms & Conditions :</h2>
        <ul>
          <li>
            Distance K.M. and time in all cases will be calculated from and to
            our Office (Garage)
          </li>
          <li>Driver Allowance will be charged extra as above mentioned.</li>
          <li>
            Parking and Toll-Tax, Permits Charges, Border Tax and Entry Charges
            guest/clients have to pay directly as actual.
          </li>
          <li>
            All the above rates are not valid in season and festival period.
          </li>
          <li>
            All the above rates are subject to changes without prior notice.
          </li>
        </ul>
      </section>

      <section id="contact">
        <div className="address-container">
          <div className="contact-elements">
            <h4>Address</h4>
            <p>
              203/2 Hanu Plaza,
              <br class="d-none d-md-block" />
              Beside Bank of Baroda, Neelbad,
              <br class="d-none d-md-block" /> Bhopal, Madhya Pradesh 462044
            </p>
          </div>
          <div className="contact-elements">
            <h4>Phones</h4>
            <ul>
              <li>
                <a href="tel:#">+91-9713429926</a>
              </li>
              <li>
                <a href="tel:#">+91-9993081414</a>
              </li>
              <li>
                <a href="tel:#">+91-7744962418</a>
              </li>
            </ul>
          </div>
          <div className="contact-elements">
            <h4>E-mails</h4>
            <ul>
              <li>
                <a href="mailto:#">shrihanutravels@gmail.com</a>
              </li>
            </ul>
          </div>
        </div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3667.329349639223!2d77.34783935!3d23.194665049999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s203%2F2%20Hanu%20Plaza%2C%20Beside%20Bank%20of%20Baroda%2C%20Neelbad%2C%20Bhopal%2C%20Madhya%20Pradesh!5e0!3m2!1sen!2sin!4v1596913019794!5m2!1sen!2sin"
          width="1000"
          height="500"
          className="gmap"
          frameborder="0"
          allowfullscreen=""
          aria-hidden="false"
          tabindex="0"
        ></iframe>
      </section>

      <section className="footer">
        <div className="filler"></div>
        <span>
          <span>&copy;&nbsp;</span>
          <span>2020</span>. All Rights Reserved.
        </span>
      </section>
    </div>
  );
};

export default HomePage;
