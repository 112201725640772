import React from "react";
import { NavLink } from "react-router-dom";
import { Link } from "react-scroll";

import "./Navlinks.css";

const Navlinks = (props) => {
  return (
    <ul className="nav-links">
      <li>
        <Link
          activeClass="active"
          to="top"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Home
        </Link>
      </li>
      <li>
        <Link
          activeClass="active"
          to="services"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Services
        </Link>
      </li>
      <li>
        <Link
          activeClass="active"
          to="fleet"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Fleet
        </Link>
      </li>
      <li>
        <Link
          activeClass="active"
          to="advantages"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Advantages
        </Link>
      </li>
      <li>
        <Link
          activeClass="active"
          to="contact"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Contact
        </Link>
      </li>
    </ul>
  );
};

export default Navlinks;
